<template>
    <div
        class="calculadora rounded-lg d-flex flex-md-row flex-column justify-md-space-between align-center align-md-stretch"
        data-cy="calculadora"
    >
        <div
            class="left-text flex-column d-flex justify-md-space-between align-md-stretch mr-auto ml-auto pa-2"
        >
            <div
                class="option mr-auto ml-auto mt-auto mb-auto ml-md-0 regime-empresa"
                data-cy="regime-empresa"
            >
                <h4>Selecione o regime de sua empresa</h4>
                <div class="button-wrapper rounded-pill d-flex ">
                    <button
                        class="alt-btn rounded-pill"
                        data-cy="mei"
                        :class="{ active: regimeEmpresa === 'mei' }"
                        @click="regimeEmpresa = 'mei'"
                    >
                        MEI
                    </button>
                    <button
                        class="alt-btn rounded-pill text-center"
                        data-cy="simples"
                        :class="{ active: regimeEmpresa === 'simples' }"
                        @click="regimeEmpresa = 'simples'"
                    >
                        Simples Nacional
                    </button>
                </div>
            </div>
            <div
                class="option mr-auto ml-auto mt-auto mb-auto ml-md-0"
                data-cy="natureza-empresa"
            >
                <h4>Atividade</h4>
                <div class="button-wrapper atividade d-flex flex-column flex-sm-row rounded-pill">
                    <button
                        class="alt-btn rounded-pill"
                        data-cy="atividade-servico"
                        :class="{ active: atividadeEmpresa === 'servico' }"
                        @click="atividadeEmpresa = 'servico'"
                    >
                        Serviço
                    </button>
                    <button
                        class="alt-btn rounded-pill"
                        data-cy="atividade-comercio"
                        :disabled="regimeEmpresa == 'simples'"
                        :class="{ active: atividadeEmpresa === 'comercio' }"
                        @click="atividadeEmpresa = 'comercio'"
                    >
                        Comércio
                    </button>
                    <button
                        class="alt-btn rounded-pill text-center"
                        data-cy="comercio-servico"
                        :disabled="regimeEmpresa == 'simples'"
                        :class="{ active: atividadeEmpresa === 'comercio-servico' }"
                        @click="atividadeEmpresa = 'comercio-servico'"
                    >
                        Comércio e Serviço
                    </button>
                </div>
            </div>
            <div
                class="d-flex flex-row mr-auto ml-auto mt-auto mb-auto ml-md-0"
                data-cy="socios-funcionarios"
            >
                <div class="option">
                    <h4>Sócios</h4>
                    <div class="button-wrapper d-flex flex-column flex-sm-row rounded-pill">
                        <input
                            v-model="socios"
                            data-cy="socios"
                            type="text"
                            class="rounded-pill socios"
                            @keypress="isNumber"
                        >
                        <v-icon
                            class="add"
                            data-cy="add-socios"
                            @click="addSocio"
                        >
                            mdi-plus
                        </v-icon>
                        <v-icon
                            class="sub"
                            data-cy="sub-socios"
                            @click="subSocio"
                        >
                            mdi-minus
                        </v-icon>
                    </div>
                </div>
                <div class="option">
                    <h4>Funcionários</h4>
                    <div class="button-wrapper d-flex flex-row rounded-pill">
                        <input
                            v-model="funcionarios"
                            data-cy="funcionarios"
                            type="text"
                            class="rounded-pill funcionarios"
                            @keypress="isNumber"
                        >
                        <v-icon
                            class="add"
                            data-cy="add-funcionarios"
                            @click="addFuncionario"
                        >
                            mdi-plus
                        </v-icon>
                        <v-icon
                            class="sub"
                            data-cy="sub-funcionarios"
                            @click="subFuncionario"
                        >
                            mdi-minus
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-column price rounded-lg d-flex align-center">
            <h3
                :key="precoFinal"
                class="preco-final mx-auto d-block"
                data-cy="preco-final"
            >
                <sup>R$</sup> {{ formatarPreco(precoFinal) }}
                <sub>por mês</sub>
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CalculadoraPreco',
    props: {
        button: {
            type: Boolean,
            default: true
        },
        showDisabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tooltipComercio: true,
            tooltipServicoComercio: false,

            regimeEmpresa: 'mei',
            atividadeEmpresa: 'servico',
            precoBase: 49.0,

            socios: 1,
            cotaSocio: 1,
            precoPorSocio: 29.0,

            funcionarios: 0,
            cotaFuncionario: 0,
            precoPorFuncionario: 29.0,

            precoFinal: 0
        }
    },
    watch: {
        socios() {
            this.socios < 1 ? (this.socios = 1) : null
            this.socios > 20 ? (this.socios = 20) : null

            if (this.regimeEmpresa === 'mei') {
                this.socios = 1
            }
            this.calcularPreco()
        },
        funcionarios() {
            this.funcionarios < 0 ? (this.funcionarios = 0) : null
            this.funcionarios > 20 ? (this.funcionarios = 20) : null

            if (this.regimeEmpresa === 'mei') {
                this.funcionarios > 1 ? (this.funcionarios = 1) : null
            }
            this.calcularPreco()
        },
        regimeEmpresa() {
            if (this.regimeEmpresa === 'mei') {
                this.precoBase = 49.0
                this.cotaSocio = 1
                this.cotaFuncionario = 0
                this.socios = 1
                this.funcionarios = 0
            }
            if (this.regimeEmpresa === 'simples') {
                this.precoBase = 79.0
                this.cotaSocio = 1
                this.cotaFuncionario = 2
                this.socios = 1
                this.funcionarios = 0
                this.atividadeEmpresa = 'servico'
            }
            this.calcularPreco()
        }
    },
    created() {
        this.calcularPreco()
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event
            let charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        addSocio() {
            this.socios += 1
        },
        subSocio() {
            this.socios -= 1
        },
        addFuncionario() {
            this.funcionarios += 1
        },
        subFuncionario() {
            this.funcionarios -= 1
        },
        calcularPreco() {
            let qtdFuncionario = this.funcionarios - this.cotaFuncionario
            qtdFuncionario = qtdFuncionario < 0 ? 0 : qtdFuncionario

            let qtdSocio = this.socios - this.cotaSocio
            qtdSocio = qtdSocio < 0 ? 0 : qtdSocio

            const totalFuncionarios = this.precoPorFuncionario * qtdFuncionario
            const totalSocios = this.precoPorSocio * qtdSocio

            this.precoFinal = this.precoBase + totalFuncionarios + totalSocios
        },
        formatarPreco(val) {
            const formatter = new Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            })
            return formatter.format(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.preco-final {
    position: relative;
}
sub {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1rem;
}
.add,
.sub {
    position: absolute;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
    color: $theme-pink;
    min-width: 0;
    padding: 0;
    &:hover {
        box-shadow: none;
        background: none;
        color: $theme-pink;
        transform: scale(1.1);
    }
}
.add {
    right: 10px;
    height: 1px;
}
.sub {
    left: 10px;
    height: 1px;
}
.secondary-color {
    background: white;
    border: 1px solid white;
    color: $theme-purple;
    &:hover {
        color: $theme-purple;
        background: white;
        transform: scale(1.05);
        border: 1px solid white;
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.4);
    }
}
.button-wrapper {
    position: relative;
    background: $theme-background-grey;
    padding: 5px;
}
button,
.alt-btn {
    min-width: none !important;
    margin: 0 auto;
    display: inline-block;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 10px;
    &:hover,
    &.active {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.4);
        background: $theme-pink;
        color: white;
    }
    &:disabled {
        cursor: default;
        color: lighten($theme-light-grey, 15%);
        background: darken($theme-background-grey, 5%);
        &:hover {
            box-shadow: none;
            color: lighten($theme-light-grey, 15%);
            background: darken($theme-background-grey, 5%);
        }
    }
}
.white-filled {
    background: white;
    &:hover {
        transform: translate3d(0, -2px, 0) scale(1.05);
        box-shadow: 0 4px 12px rgba(30, 60, 90, 0.4);
    }
}
.calculadora {
    display: inline-block;
    text-align: left;
    background: white;
    min-height: 350px;
}
h4 {
    font-weight: 300;
    color: $theme-light-grey;
}
h3 {
    color: white;
}
sup {
    font-size: 1.7rem;
}
input {
    text-align: center;
    background: white;
    height: 40px;
    width: 100px;
    padding: 10px 20px;
    margin: 0;
    display: inline-block;
}
.price {
    white-space: nowrap;
    margin: 10px;
    background: linear-gradient(120deg, $theme-pink, $theme-purple);
    padding: 2rem;
    justify-content: space-evenly;
    width: 250px;
}
.natureza {
    background: transparent !important;
}
@media screen and (min-width: 600px) {
    .natureza {
        background: $theme-background-grey !important;
    }
}
@media screen and (min-width: 450px) {
    .button-wrapper {
        white-space: nowrap;
    }
    .calculadora {
        width: 380px;
    }
}
@media screen and (min-width: 960px) {
    .calculadora {
        width: 650px;
    }
}
</style>
