<template>
    <div>
        <div
            class="header mb-5"
            data-cy="content-header"
        >
            <span
                id="title"
                class="text-h4 font-weight-bold"
            >Nossos preços</span>
            <p
                id="subtitle"
                class="text-h5 font-weight-light grey--text text--darken-2"
            >
                Faça uma estimativa do custo mensal
            </p>
        </div>
        <div>
            <CalculadoraPreco />
        </div>
        <p class="text-h6 font-weight-light grey--text text--darken-3">
            Você só será cobrado quando seu cadastro for
            <span
                class="text-h6 font-weight-light highlight"
                data-content="efetivado"
            >efetivado</span>
            por nossa equipe
        </p>
        <v-container>
            <v-row
                slot="buttons"
                dense
                justify-sm="space-between"
                justify="center"
            >
                <v-col
                    cols="auto"
                    sm="auto"
                >
                    <v-btn
                        rounded
                        filled
                        outlined
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6 return-step"
                        data-cy="return-step"
                        elevation="3"
                        x-large
                        @click.prevent="returnStep()"
                    >
                        <v-icon left>
                            mdi-arrow-left
                        </v-icon>
                        Voltar
                    </v-btn>
                </v-col>
                <v-col
                    cols="auto"
                    offset-sm="0"
                >
                    <v-btn
                        rounded
                        filled
                        color="primary"
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6 next-step"
                        data-cy="next-step"
                        elevation="3"
                        x-large
                        @click="nextStep()"
                    >
                        Continuar
                        <v-icon right>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CalculadoraPreco from "@/components/CalculadoraPreco";
export default {
    name: "SimulacaoContabilidade",
    components: {
        CalculadoraPreco,
    },
    methods: {
        returnStep() {
            return this.$store.commit("returnStep");
        },
        nextStep() {
            return this.$store.commit("nextStep");
        },
    },
};
</script>

<style lang="scss" scoped>
span.highlight {
  z-index: 2;
  white-space: nowrap;
  position: relative;
  color: transparent;
  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    background: #f9f821;
    color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }
  &::after {
    z-index: -1;
    content: attr(data-content);
    position: absolute;
    color: #424242;
    top: auto;
    bottom: 16px;
    left: 0;
    width: 100%;
    height: 50%;
  }
  &::selection {
    color: white;
    background: #accef7;
  }
}
</style>
